import React from 'react';
import { Field } from 'react-jeff';
import cx from 'classnames';

interface FormProps {
  onFormSubmit: Function;
}
export function Form({ onFormSubmit, ...props }: FormProps & React.HTMLProps<HTMLFormElement>) {
  return (
    <form
      {...props}
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();
        onFormSubmit();
      }}
    />
  );
}

interface InputProps {
  label?: string;
  id: string;
  field: Field<string>;
}
export function Input({ label, id, field, ...props }: InputProps & React.HTMLProps<HTMLInputElement>) {
  let { onChange, ...rest } = field.props;
  // only show first one;
  let error = field.dirty ? field.errors[0] ?? '' : '';
  return (
    <div className="relative">
      {label && (
        <label
          className={cx('block sky-h5 md:sky-h7 font-bold whitespace-no-wrap', {
            'text-error': error,
          })}
          htmlFor={`input-${id}`}>
          {label}
        </label>
      )}
      <input
        id={`input-${id}`}
        className={cx('block w-full sky-input sky-input--text', {
          'sky-input--error': error,
        })}
        {...rest}
        {...props}
        onChange={event => {
          onChange(event.currentTarget.value);
        }}
      />
      <div className="absolute top-100 left-0 text-error whitespace-no-wrap">{error}</div>
    </div>
  );
}
