import React from 'react';
import { frequency, telPhoneNumber, useToastContainer, validateEmail } from '@sky-tv-group/shared';

import { format, parseISO, addDays } from 'date-fns';
import { Switch } from './switch';
import { T_CustomerPrefs } from '@sky-tv-group/shared/src/types';
import { SWRResponse } from 'swr';
import { useState } from 'react';
import { Form, Input } from './form';
import { useField, useForm } from 'react-jeff';
import { Button } from './button';

const RadioButton = ({ checked, text, onChange }: { checked: boolean; text: string; onChange: any }) => {
  return (
    <div onClick={onChange} className="inline-flex items-center mr-2 p-1">
      <div className={' border rounded-full p-0.5 ' + (checked ? 'border-success' : 'border-gray-dark')}>
        <div className={' border rounded-full w-4 h-4 ' + (checked ? 'bg-success' : 'bg-gray-dark')}></div>
      </div>
      <div className="ml-2 capitalize">{text}</div>
    </div>
  );
};

interface Props {
  getPrefs: () => SWRResponse<T_CustomerPrefs, any>;
  updatePrefs: (payload: T_CustomerPrefs, update?: boolean) => Promise<T_CustomerPrefs>;
  email?: string;
  updateEmail?: (email: string) => Promise<void>;
}

export let CommunicationPreference = ({ getPrefs, updatePrefs, email, updateEmail }: Props) => {
  const [editingEmail, editingEmailSet] = useState(false);
  const { addToast, addSuccessToast } = useToastContainer();

  const { data: prefs, isValidating: loading } = getPrefs();

  const isUnsubscribeAll = prefs?.emails?.unsubscribeAll ?? false;

  let emailField = useField({
    defaultValue: email ?? '',
    required: true,
    validations: [validateEmail],
  });
  let form = useForm({
    fields: [emailField],
    onSubmit: () => {
      updateEmail?.(emailField.value);
      editingEmailSet(false);
    },
  });
  let disabled = !form.valid;

  let snoozeDateStr = prefs?.emails?.snoozeDate ? format(parseISO(prefs?.emails?.snoozeDate), 'do MMM yyyy') : '';

  if (loading) {
    return null;
  }
  if (!prefs || !prefs.emails) {
    return email ? (
      <div className="text-center sky-h5 p-8">
        {`Something didn't work there. If the problem persists please contact our Sky Crew on ${telPhoneNumber}`}.
      </div>
    ) : (
      <div className="text-center sky-h5 p-8">
        Oops! The information contained in the link seems to be invalid. Please go back and try the link again.
        <br />
        If the problem persists, please contact our Sky Crew on {telPhoneNumber}.
      </div>
    );
  }

  const handleChange = async (key: string, e: any) => {
    let emails = prefs?.emails;
    let resetFrequency = false;
    let fields: Array<keyof T_CustomerPrefs['emails']> = [
      'broadbandOffers',
      'snooze',
      'personalRecommendation',
      'rewardsCompetition',
      'otherOffers',
      'tvOffers',
      'otherContent',
    ];
    if (emails.frequency === 'none' && fields.every(f => emails[f] === (f === key ? true : false))) {
      resetFrequency = true;
    }

    let obj: T_CustomerPrefs = {
      ...prefs,
      emails: {
        ...prefs.emails,
        unsubscribeAll: false,
        frequency: resetFrequency ? 'weekly' : emails.frequency,
        [key]: e,
      },
    };

    if (key === 'snooze') {
      obj.emails.snoozeDate = emails.snooze === false ? format(addDays(Date.now(), 4 * 7), 'yyyy-MM-dd') : null;
    }

    await updatePrefs(obj);
  };
  const handleSMS = async () => {
    await updatePrefs({ ...prefs, sms: { ...prefs.sms, unsubscribeAll: !prefs.sms.unsubscribeAll } });
  };

  const handleUnsubscribeAll = async () => {
    let emails = { ...prefs?.emails };
    if (!isUnsubscribeAll) {
      emails.broadbandOffers = false;
      emails.frequency = 'none';
      emails.snooze = false;
      emails.snoozeDate = null;
      emails.personalRecommendation = false;
      emails.rewardsCompetition = false;
      emails.otherOffers = false;
      emails.tvOffers = false;
      emails.otherContent = false;
    } else {
      emails.frequency = 'weekly';
    }
    emails.unsubscribeAll = !isUnsubscribeAll;

    await updatePrefs({
      ...prefs,
      emails: {
        ...emails,
      },
    });
  };

  const save = async () => {
    try {
      await updatePrefs(prefs, true);
      //Changes have been saved successfully!

      addSuccessToast({
        message: 'Changes have been saved successfully!',
      });
    } catch (err) {
      addToast({
        message: 'Sorry, there was a problem saving the changes. Please try again. ',
        type: 'error',
        title: 'Alert',
      });
    }
  };

  return (
    <div className="container m-auto ">
      <div className="py-2">
        <div className="md:px-8">
          {email && !isUnsubscribeAll && (
            <div className=" p-4 px-6 md:px-0 border-b min-h-24">
              <div className="flex justify-start items-center">
                <div>
                  <p className="sky-h5 md:sky-h5 py-1 pr-3">Email address:</p>
                </div>
                <div className="px-2 pt-2">
                  {editingEmail ? (
                    <Form onFormSubmit={form.props.onSubmit} className="flex px-2">
                      <Input id="email-address" label="" field={emailField} />
                      <button className="underline text-blue-pure px-4" disabled={disabled}>
                        {' '}
                        Save
                      </button>
                    </Form>
                  ) : (
                    <div>
                      <span className="sky-h7 md:sky-h7 text-gray-darker">{email}</span>{' '}
                      <span
                        className="underline cursor-pointer text-blue-pure px-4"
                        onClick={() => {
                          editingEmailSet(true);
                        }}>
                        Edit
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <p className="sky-h7 text-gray-darker pt-4">
                This is your email for all communications from Sky, including your billing. Changing this will not
                affect your Account login.
              </p>
            </div>
          )}
          <div id="section-marketing-snooze" className="flex justify-between items-center p-4 px-6 md:px-0 border-b">
            <div className="flex ">
              <p className="sky-h5 md:sky-h5 mr-10">Snooze all emails for 4 weeks</p>
              {prefs.emails.snooze && snoozeDateStr && (
                <span className="bg-success text-white p-1 px-3 rounded  ml-5">Snoozed until: {snoozeDateStr}</span>
              )}
            </div>
            <div>
              <Switch value={prefs?.emails?.snooze} onChange={e => handleChange('snooze', e)} />
            </div>
          </div>

          {/* section starts */}
          <div
            id="section-marketing-weekly-watch"
            className="flex justify-between items-center p-4 px-6 md:px-0 border-b min-h-24">
            <div>
              <p className="sky-h5 md:sky-h5 font-bold py-2">What to watch</p>
              <p className="sky-h6 md:sky-h6 font-bold py-1">Weekly Content Guide</p>
              <p className="sky-h7 md:sky-h7">The best way to discover what's on each week.</p>
            </div>
            <div>
              <div className="flex justify-center align-middle  flex-wrap">
                {frequency?.map(value => (
                  <RadioButton
                    key={value}
                    onChange={() => handleChange('frequency', value)}
                    checked={prefs.emails.frequency === value}
                    text={value}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="p-4 py-8 flex items-center justify-center">
            <Button onClick={save}>Save</Button>
          </div>
          <div className="flex justify-between items-center p-4 px-6 md:px-0 border-b min-h-24">
            <div>
              <p className="sky-h6 md:sky-h6 font-bold py-1">Personal Recommendations</p>
              <p className="sky-h7 md:sky-h7">Recommendations on what to watch based on what you’ve already watched.</p>
            </div>
            <div>
              <Switch
                value={prefs?.emails?.personalRecommendation || false}
                onChange={e => handleChange('personalRecommendation', e)}
              />
            </div>
          </div>
          <div className="flex justify-between items-center p-4 px-6 md:px-0 border-b min-h-24">
            <div>
              <p className="sky-h6 md:sky-h6 font-bold py-1">Additional / Other Content Discovery</p>
            </div>
            <div>
              <Switch value={prefs?.emails?.otherContent || false} onChange={e => handleChange('otherContent', e)} />
            </div>
          </div>
          <div className="flex justify-between items-center p-4 px-6 md:px-0 border-b min-h-24">
            <div>
              <p className="sky-h5  md:sky-h5 font-bold py-2">Offers and Rewards</p>
              <p className="sky-h6 md:sky-h6 font-bold py-1">Sky TV Offers</p>
              <p className="sky-h7 md:sky-h7"> Special deals and savings on everything Sky TV</p>
            </div>
            <div>
              <Switch value={prefs?.emails?.tvOffers} onChange={e => handleChange('tvOffers', e)} />
            </div>
          </div>
          <div className="flex justify-between items-center p-4 px-6 md:px-0 border-b min-h-24">
            <div>
              <p className="sky-h6 md:sky-h6 font-bold py-1">Sky Broadband Offers</p>
              <p className="sky-h7 md:sky-h7"> Special deals and savings on Sky Broadband</p>
            </div>
            <div>
              <Switch value={prefs?.emails?.broadbandOffers} onChange={e => handleChange('broadbandOffers', e)} />
            </div>
          </div>
          <div className="flex justify-between items-center p-4 px-6 md:px-0 border-b min-h-24">
            <div>
              <p className="sky-h6 md:sky-h6 font-bold py-1">Other Offers</p>
              <p className="sky-h7 md:sky-h7">Offers on event tickets and other entertainment related offers</p>
            </div>
            <div>
              <Switch value={prefs?.emails?.otherOffers} onChange={e => handleChange('otherOffers', e)} />
            </div>
          </div>
          <div className="flex justify-between items-center p-4 px-6 md:px-0  min-h-24 border-b">
            <div>
              <p className="sky-h6 md:sky-h6 font-bold py-1">Sky Rewards, competitions, and giveaways</p>
              <p className="sky-h7 md:sky-h7">Rewards, tickets and more on us!</p>
            </div>
            <div>
              <Switch value={prefs?.emails?.rewardsCompetition} onChange={e => handleChange('rewardsCompetition', e)} />
            </div>
          </div>

          <div className="flex justify-between items-center p-4 px-6 md:px-0  min-h-24">
            <div>
              <p className="sky-h6 md:sky-h6 font-bold py-1">SMS</p>
              <p className="sky-h7 md:sky-h7">
                We will still send you essential information about your Sky subscription and services by SMS
              </p>
            </div>
            <div>
              <Switch value={!prefs?.sms.unsubscribeAll} onChange={handleSMS} />
            </div>
          </div>
        </div>
        <div
          id="section-marketing-email"
          className="flex sky-bg-gray-light justify-between  p-4 px-6 md:px-0  border-t-2  ">
          <label>
            <input type="checkbox" className="w-6 h-6 m-4" checked={isUnsubscribeAll} onChange={handleUnsubscribeAll} />

            <span className="sky-h5  md:sky-h5 font-bold py-2">Unsubscribe from all email</span>
            <p className="sky-h7  md:sky-h7  py-1 pl-6">
              Just a reminder, we will still send you emails with helpful information about your Sky subscription and
              services.{' '}
            </p>
          </label>
          <div></div>
        </div>
        <div className="p-4 flex items-center justify-center">
          <Button onClick={save}>Save</Button>
        </div>
      </div>
    </div>
  );
};
