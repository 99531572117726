import axios, { AxiosRequestConfig } from 'axios';

import { kkServiceResponseInterceptor, parseQuery } from '@sky-tv-group/shared';

import { BACKEND_ENDPOINT, KONAKART_ENDPOINT, NAVIGATION_URL } from './config';

export const agentNavigation = axios.create({ baseURL: NAVIGATION_URL });

let requestInterceptor = async (config: AxiosRequestConfig) => {
  const query = parseQuery(window.location.search);
  const subscriberID = query['sk'];
  config.headers['subscriberID'] = subscriberID;
  return config;
};

export const agentMyAccountNonLoggedIn = axios.create({
  baseURL: BACKEND_ENDPOINT,
});
agentMyAccountNonLoggedIn.interceptors.request.use(requestInterceptor);

export const agentKK = axios.create({
  baseURL: KONAKART_ENDPOINT,
});
agentKK.interceptors.response.use(resp => resp, kkServiceResponseInterceptor);
