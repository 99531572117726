import create from 'zustand';
import { NavigationLinks } from '@sky-tv-group/shared';
import { navService } from './services';
import { NAVIGATION_URL } from './config';

interface Store {
  navLinks: NavigationLinks;
  loadLinks: () => void;
}
let [useStore, api] = create<Store>(() => {
  return {
    navLinks: {
      logoUrl: '/',
      logoSrc: '/',
      TVGuideURL: '/',
      header: [],
      side: [],
      footer: [],
      social: [],
      corporate: [],
    },
    loadLinks: async () => {
      try {
        const navLnk = await navService.getLinks(NAVIGATION_URL);
        set({ navLinks: navLnk });
      } catch (err) {
        console.error(err);
      }
    },
  };
});

let set = api.setState;
export { useStore, set };
