import React from 'react';
import { MarketingRectangle } from './MarketingRectangle';

interface NewMarketingCardProps {
  urlLink: string;
  mRectTag?: string;
  mRectColor?: string;
  children?: React.ReactNode;
}

/*This component is the background image of the card with marketing rectangle.
  The children in this component are just templates that configure various text layouts.
*/
export const NewMarketingCard = ({ urlLink, mRectTag, mRectColor, children }: NewMarketingCardProps) => {
  return (
    <>
      <div
        className="relative z-0 bg-no-repeat bg-auto rounded-lg md:min-h-full min-h-330px"
        style={{
          backgroundImage: `url(${urlLink})`,
        }}>
        {mRectTag && <MarketingRectangle mRectColor={mRectColor} mRectTag={mRectTag} />}
        <div className="absolute z-1 text-white mx-5 mb-4 bottom-0">{children}</div>
      </div>
    </>
  );
};
