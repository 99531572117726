import React from 'react';
import { CommunicationPreference, Disclosure } from '@sky-tv-group/components';
import { myAccountSWR } from '../swr';

export let CommPrefContainer = () => {
  return (
    <Disclosure header="Communication Preferences">
      <CommunicationPreference getPrefs={myAccountSWR.useCustomerPref} updatePrefs={myAccountSWR.updateCustomerPref} />;
    </Disclosure>
  );
};
