import { ToastContainer } from '@sky-tv-group/components';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CommPrefContainer } from './screens/CommPrefs';
import { FooterPage } from './screens/Footer';
import { HeaderPage } from './screens/Header';
import { useStore } from './store';

const Home = () => {
  let load = useStore(s => s.loadLinks);
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navLinks = useStore(s => s.navLinks);
  return (
    <>
      <Router>
        <div>
          <Route path="/header">
            <HeaderPage {...navLinks} />
          </Route>
          <Route exact path="/footer">
            <FooterPage {...navLinks} />
          </Route>
          <Route exact path="/comm-prefs">
            <CommPrefContainer />
          </Route>
        </div>
      </Router>
      <ToastContainer></ToastContainer>
    </>
  );
};

export const App = Home;
